/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "swiper/css/bundle";


//Consultare

@import './theme/consultare.theme.scss';


.ver-como{
  padding: 18px 16px 0px;
  font-size: 19px;
  ion-icon {
      margin: 1px 5px 1px;
      font-weight: bold;
      color: #2e3f51;
  }
}



a{
  color:  #a4a4a4//TODO::Pegar cores
}

h4 {
  font-size: 22px;
}

h4 {
  &.about-a {
      color:white;
      font-size: 2rem;
      // margin-bottom: -56px;
      margin-top: 112px;
  }
  &.about-b {
      // margin-bottom: -27px;
      margin-top: -14px;
      font-size: 3rem;
      color:white;
  }
}


.cat-rows .cat-box p
{
  // color: #2d8fff !important;//TODO::Importate
  letter-spacing: 0pt;
  text-align: center;
}




.cat-rows .cat-box {
  border-radius: 0px !important;
  padding: 10px !important;
  border-bottom: 3px #5abbe6 solid;
  height: 100% !important;
}

#-header {
  min-height: 175px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #32ace1;
}

[background-size] {
  //@include darkening-blend-mode;
  width: 100%;
  display: table;
  position: relative;
  z-index: 999;
  .title {
    display: table-cell;
    text-align: left;
    vertical-align: bottom;
    [parallax-title] {
      position: relative;
      z-index: 9;
      margin-top: 0;
    }
    [parallax-subtitle] {
      margin-bottom: 0;
      position: relative;
      z-index: 9;
    }
  }
}


  .header-name {
      text-align: center;
      text-shadow: 1px 1px 0 rgba(140, 140, 140, 0.6);
      line-height: 40px;
      margin-bottom: 17px !important;
  }


    .header-name p::first-letter {
      font-size: 200%;
      color: #8A2BE2;
  }
  span.sub {
    position: relative;
}


.bgmenu  {
  border-bottom: #31ace2 4px solid;
height: 100%;


.item.item-block.item-ios,
  .item.item-block.item-md
  {
    background-color: #f2f2f2;
}

}

.scroll-content {
  overflow-y: auto;
  background-color: #F2F2F2;
  height: 100%;
}
  .menu-logo {


      text-align: center;
      padding-top: 36px;
      padding-bottom: 36px;
      // border-top: 9px #31ace2 solid;
      background: url(./assets/imgs/bgConsultare.jpg) bottom left;
      background-size: cover;

}
.menu-logo-image {
  text-align: center;
  margin-bottom: 5px;

}
.menu-logo-image img {
  border-radius: 29%;
  padding: 4px;
  border: 1px #f2f2f2 solid;
  width: 100px;
}



.header-name{
  margin-top: -100px;
  margin-bottom: 50px;
  color: #fff;
}
//Header when scroll is at top of page
.header {
  position: absolute;
  z-index: 1000;
  color: #ffffff;
  // background: #00000080;
  width: 100%;
}
.menu-icon.icon.icon-md.bar-buttons.bar-buttons-md.icon-md-light.ion-md-menu.bar-buttons-md-light {
  color: #ffffff;
}
.menu-icon {
  color: #ffffff;
  font-size: 175%;
}

//Header when scrolled down the page
.header.top {
  position: absolute;
  z-index: 1000;
  color: #ffffff;
  background: #00000000;
  width: 100%;
  background-image: url(./assets/imgs/navbar.svg);
  background-size: 100%;
  background-position: top center;
  background-attachment: fixed;
}
.header.top .menu-icon.icon.icon-md.bar-buttons.bar-buttons-md.icon-md-light.ion-md-menu.bar-buttons-md-light {
  color: #ffffff;
}
.header.top .menu-icon {
  font-size: 175%;
}








ion-navbar ion-icon, ion-title, .toolbar-title {
  color: white !important;
}



.toolbar-background{
  // position: relative;
  background-image: url('./assets/imgs/bgConsultare.jpg') no-repeat transparent;
  //  background-size: cover;
  background-color: #2d8fff;//TODO::Pegar cores
}




.cat-rows {
  margin-bottom: 10px;

  .cat-box {

    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.103);
    margin: 5px;
    min-height: 15vh;
    padding: 5px;
    text-align: left;
    height: auto;

    .catimgholder{
      width: 103px;
      img{
        max-height: 150px;
        height:125px;
        max-width: 80%;
      }

    }
    p{
      margin: 0;
      color: #95C6FF;
      font-size: 100%;
      font-weight: 500;
    }

  }
}

.checkbox{
  border-color:#95C6FF!important;
}

.slide-zoom p {
  font-size: 23px;
}


.bar-button-default-ios, .bar-button-default.bar-button-ios-default, .bar-button-clear-ios-default {
  color: #488aff00;
  background-color: transparent;
}

page-firstaid {
    .title {
    padding-right: 73px;
  }
}
page-firstaid, page-incident {

  h4 {font-size: 28px;}
  span.sub {
    font-size: 19px !important;
    color: #58585a;
   position: absolute;
}
}

@media only screen and (max-width: 320px) {
  .cat-rows .cat-box p {
    font-size: 117%;
    font-weight: 500;
}

.imageleft {
  top: 111px !important;
}
}

@media screen and (min-width: 767px) {

  .container {
    margin-bottom: 10px;
    padding: 0px 12vw;}

  .container-src {padding: 0px 12vw;}
  .container-v {
      padding-top: 4vw !important;
      padding-bottom: 4vw !important;
  }
  page-firstaid, page-incident {
    h4 {font-size: 5rem;}
    span.sub {
      font-size: 36px !important;
      color: #58585a;
     // top: -16px;
  }
  }
}


@media screen and (min-width: 1200px) {
  .header.top, .toolbar {
    background: #000000 !important;
    background-image: unset !important;
}
.header-name {
  margin-top: -100px;
  margin-bottom: 50px;
  color: #fff;
  margin-bottom: -27px;
  font-size: 5rem;
  color: white;
  text-align: left !important;
  position: absolute;
  bottom: -27px;
  padding-left: 12vw !important;
  padding-bottom: 65px !important;
  width: 100%;
}
.imageleft {
  border-radius: 50%;
  padding: 8px;
  border: 0px #ffffff solid;
  width: 210px !important; height: 210px !important;
  background-color: #f2f2f2 !important;
  position: absolute ;
  z-index: 1111111;
  top: 358px !important;
  right: 10vw !important;
  .header-icon {
    width: 100%;
  }
}
.title {
  display: initial !important;}
#-header {
  &::before {
    width: 71px;
    height: 71px;
    background: url(./assets/imgs/ceta-b.svg) center no-repeat;
    background-size: 100%;
    content: '';
    position: absolute;
    bottom: -73px;
    margin: auto;
    left: 0;
    right: 0;
  }
  height: 481px;
  background-position: bottom;
}
    .cat-box {
      display: flex;
      height: 87px !important;
      }
    .cat-box p {
      align-self: center;
    }

    .clear-space {
      height: 90px;
    }
}


// OfflineMode
.offlineMode {
 position: absolute;
    width: 100vw;
    height: max-content;
    top: 0;
    bottom: 0;
    margin: auto;
    padding-right: 30px;
}


@media screen and (max-height: 340px) {
  .offlineModeImg {
    max-width: 21% !important;
}
}


