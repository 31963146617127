.input-wrapper {
    padding-left: 20px;
}


.item-md.item-block .item-inner {
    padding-right: 8px;
}

page-team p {
    margin: 5px;
}

page-team .item-md {
    border: 0.2px solid #dedede;
    // background-color: #eaeaea4d;
  	/*  background-image: url(../assets/imgs/list-item.svg);*/
    background-size: 100%;
}

page-team .item-md.item-block .item-inner {
    padding-right: 8px;
    border-bottom: 0px;
}






